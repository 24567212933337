<template>
  <v-app>
    <vue-particles
      color="#240b36"
      :particleOpacity="0.7"
      :particlesNumber="50"
      shapeType="star"
      :particleSize="4"
      linesColor="#c31432"
      :linesWidth="2"
      :lineLinked="true"
      :lineOpacity="0.4"
      :linesDistance="150"
      :moveSpeed="3"
      :hoverEffect="true"
      hoverMode="grab"
      :clickEffect="true"
      clickMode="push"
    >
    </vue-particles>
    <v-container class="container">
      <div>
        <v-app-bar class="bar1" :elevation="11">
          <v-row align="center" class="d-flex align-center">
            <v-col :cols="4" align="center">
              <v-img
                src="../assets/images/logo.png"
                class="logo"
                alt="kereta-sewa-seremban"
                @click="$router.push({ path: '/' })"
              />
            </v-col>
            <v-col :cols="4">
              <ul
                class="nav-items"
                style="flex-direction: row !important; display: flex !important"
              >
                <li>
                  <a
                    href="https://www.facebook.com/profile.php?id=100095115500120"
                    target="_blank"
                  >
                    <v-icon
                      size="40"
                      style="
                      text-decoration: none !important;
                          background-image: linear-gradient(to right, #ad5389, #3c1053);
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        "
                      class="btnWa"
                      @click="trackButtonClick('FacebookGroup')"
                      >mdi-facebook</v-icon
                    >
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/jrv_keretasewa/"
                    target="_blank"
                  >
                    <v-icon
                      size="40"
                      style="
                      text-decoration: none !important;
                          background-image: linear-gradient(to right, #ad5389, #3c1053);
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        "
                      class="btnWa"
                      @click="trackButtonClick('InstagramGroup')"
                      >mdi-instagram</v-icon
                    >
                  </a>
                </li>
                <li>
                  <a
                    href="https://chat.whatsapp.com/DVZXvnBiDqS0Z9jkHwL9yT"
                    target="_blank"
                  >
                    <v-icon
                      size="40"
                      style="
                      text-decoration: none !important;
                          background-image: linear-gradient(to right, #ad5389, #3c1053);
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        "
                      class="btnWa"
                      @click="trackButtonClick('WhatsappGroup')"
                      >mdi-whatsapp</v-icon
                    >
                  </a>
                </li>
                <li>
                  <a href="https://t.me/+ILGvKb39imRkMDNl" target="_blank">
                    <v-icon
                      size="40"
                      style="
                      text-decoration: none !important;
                          background-image: linear-gradient(to right, #ad5389, #3c1053);
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        "
                      class="btnWa"
                      @click="trackButtonClick('TelegramGroup')"
                      >mdi-telegram</v-icon
                    >
                  </a>
                </li>
              </ul>
              <br />
              <v-card-text class="d-flex align-center justify-center title">
                KERETA SEWA SEREMBAN JRV
              </v-card-text>
            </v-col>
          </v-row>
        </v-app-bar>
      </div>
      <v-card
        class="grid"
        :elevation="11"
        style="
          opacity: 1 !important;
          background: rgba(249, 236, 224, 0.59) !important !important;
        "
      >
        <v-card-text class="text-center mx-auto header"
          >Cars Available Today</v-card-text
        >
        <v-tabs
          v-model="tab"
          centered
          next-icon="mdi-arrow-right-bold-box-outline"
          prev-icon="mdi-arrow-left-bold-box-outline"
          show-arrows
          grow
        >
          <v-tab
            v-for="(categoryCars, categoryName) in groupedCars"
            :key="categoryName"
            :value="categoryName"
            :href="`#${categoryName}`"
          >
            <v-card-text class="text-center mx-auto header">{{
              categoryName
            }}</v-card-text>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab" touchless>
          <v-tab-item
            v-for="(categoryCars, categoryName) in groupedCars"
            :key="categoryName"
            :value="categoryName"
          >
            <v-container>
              <v-card
                elevation="5"
                class="grid4"
                v-for="car in categoryCars"
                :key="car._id"
                style="padding-bottom: 1%; margin-top: 20px !important"
              >
                <!-- <v-card class="grid5" :elevation="5" shaped> -->
                <v-row
                  class="d-flex align-center justify-center"
                  style="margin-top: 20px !important"
                >
                  <div class="text-center mx-auto text4">
                    {{ car.make }} {{ car.model }}
                  </div>
                </v-row>
                <!-- </v-card> -->
                <!-- <v-spacer></v-spacer><v-spacer></v-spacer> -->
                <v-card
                  class="grid4"
                  :elevation="5"
                  shaped
                  style="
                    margin-top: 20px !important ;
                    border-radius: inherit !important;
                  "
                >
                  <v-row class="d-flex align-center justify-center">
                    <v-btn
                      class="justify-end btnwa2"
                      :href="`https://wa.me/126565477?text=I'm%20interested%20in%20your%20${car.make}%20${car.model}%20for%20rental%20from%20JRV%20Rental%20Services`"
                      target="_blank"
                      @click="trackButtonClick('WhatsappMessageInactive', car)"
                      ><v-icon
                        style="color: white !important"
                        alt="WhatsApp icon"
                        aria-label="Contact JRV-Car-Rental-Seremban on WhatsApp"
                        >mdi-whatsapp</v-icon
                      ><v-card-text class="text-center header3"
                        >Book Now</v-card-text
                      ></v-btn
                    >
                  </v-row>
                </v-card>
                <carousel-3d>
                  <slide
                    v-for="(image, i) in car.imageUrl"
                    :index="i"
                    :key="i"
                    style="
                      max-width: 250px !important;
                      max-height: 250px !important;
                    "
                  >
                    <template
                      slot-scope="{ index, isCurrent, leftIndex, rightIndex }"
                    >
                      <img
                        :data-index="index"
                        :class="{
                          current: isCurrent,
                          onLeft: leftIndex >= 0,
                          onRight: rightIndex >= 0,
                        }"
                        :src="image.url"
                        width="250px"
                        height="250px"
                      />
                    </template>
                  </slide>
                </carousel-3d>
                <v-row align="center" class="d-flex align-center">
                      <v-col>
                        <v-card-text
                          class="d-flex align-center justify-center price"
                        >
                          <textra
                            :data="car.pricePerDay"
                            :timer="3"
                            filter="left-right"
                            infinite
                          />
                        </v-card-text>
                      </v-col>
                    </v-row>
                    <v-row align="center" class="d-flex align-center">
                      <v-col>
                        <v-card-text
                          class="d-flex align-center justify-center price2"
                        >
                          <textra
                            :data="car.pricePer3Days"
                            :timer="3"
                            filter="left-right"
                            infinite
                          />
                        </v-card-text>
                      </v-col>
                    </v-row>
                    <v-row align="center" class="d-flex align-center">
                      <v-col>
                        <v-card-text
                          class="d-flex align-center justify-center price3"
                        >
                          <textra
                            :data="car.pricePerWeek"
                            :timer="3"
                            filter="left-right"
                            infinite
                          />
                        </v-card-text>
                      </v-col>
                    </v-row>
                    <!-- <v-row align="center" class="d-flex align-center">
                      <v-col>
                        <v-card-text
                          class="d-flex align-center justify-center price4"
                        >
                          <textra
                            :data="car.pricePerMonth"
                            :timer="3"
                            filter="left-right"
                            infinite
                          />
                        </v-card-text>
                      </v-col>
                    </v-row> -->
                <!-- <v-row align="center" class="d-flex align-center">
                  <v-col class="d-flex align-center mx-auto">
                    <v-card-text class="text-center mx-auto color"
                      >Available Colors :
                    </v-card-text>
                    <v-card
                      min-height="20px"
                      min-width="20px"
                      :style="{ backgroundColor: car.colors }"
                      style="
                        margin-left: 2px !important;
                        margin-right: 2px !important;
                      "
                    >
                    </v-card>
                  </v-col>
                </v-row> -->
                <v-row
                  align="center"
                  class="d-flex align-center"
                  style="margin-right: -12px !important"
                >
                  <!-- <div class="d-flex align-items-center justify-content-center"> -->
                  <div v-if="car.features[0].type">
                    <v-col class="d-flex align-center mx-auto">
                      <div v-if="car.features[0].type == 'SEDAN'">
                        <v-img
                          src="../assets/images/sedan.png"
                          style="margin-left: 1% !important"
                          width="40px"
                        ></v-img>
                      </div>
                      <div v-if="car.features[0].type == 'HATCHBACK'">
                        <v-img
                          src="../assets/images/hatchback.png"
                          style="margin-left: 1% !important"
                          width="40px"
                        ></v-img>
                      </div>
                      <div v-if="car.features[0].type == 'SUV'">
                        <v-img
                          src="../assets/images/suv.png"
                          style="margin-left: 1% !important"
                          width="40px"
                        ></v-img>
                      </div>
                      <div v-if="car.features[0].type == 'MPV'">
                        <v-img
                          src="../assets/images/minivan.png"
                          style="margin-left: 1% !important"
                          width="40px"
                        ></v-img>
                      </div>
                    </v-col>
                  </div>
                  <div v-if="car.features[0].transmission">
                    <v-col class="d-flex align-center mx-auto">
                      <div v-if="car.features[0].transmission == 'Auto'">
                        <v-img
                          src="../assets/images/auto.png"
                          style="margin-left: 1% !important"
                          width="30px"
                        ></v-img>
                      </div>
                      <div v-if="car.features[0].transmission == 'Manual'">
                        <v-img
                          src="../assets/images/manual.png"
                          style="margin-left: 1% !important"
                          width="30px"
                        ></v-img>
                      </div>
                    </v-col>
                  </div>
                  <!-- <div v-if="car.features[0].seats">
                        <div v-if="car.features[0].seats == '5'">
                          <Icon
                            icon="iconamoon:number-5-circle"
                            width="40"
                            style="color: #ad5389"
                          />
                        </div>
                        <div v-if="car.features[0].seats == '7'">
                          <Icon
                            icon="iconamoon:number-7-circle"
                            width="40"
                            style="color: #ad5389"
                          />
                        </div>
                        <div v-if="car.features[0].seats == '12'">
                          <Icon
                            icon="iconamoon:number-12-circle"
                            width="40"
                            style="color: #ad5389"
                          />
                        </div>
                      </div> -->
                  <div v-if="car.features[0].petrol">
                    <v-col class="d-flex align-center mx-auto">
                      <div v-if="car.features[0].petrol == '95'">
                        <v-img
                          src="../assets/images/95.png"
                          style="margin-left: 1% !important"
                          width="30px"
                        ></v-img>
                      </div>
                      <div v-if="car.features[0].seats == '97'">
                        <v-img
                          src="../assets/images/97.png"
                          style="margin-left: 1% !important"
                          width="30px"
                        ></v-img>
                      </div>
                      <div v-if="car.features[0].seats == '99'">
                        <v-img
                          src="../assets/images/97.png"
                          style="margin-left: 1% !important"
                          width="30px"
                        ></v-img>
                      </div>
                      <div v-if="car.features[0].seats == '100'">
                        <v-img
                          src="../assets/images/100.png"
                          style="margin-left: 1% !important"
                          width="30px"
                        ></v-img>
                      </div>
                      <div v-if="car.features[0].seats == 'Diesel'">
                        <v-img
                          src="../assets/images/diesel.png"
                          style="margin-left: 1% !important"
                          width="30px"
                        ></v-img>
                      </div>
                    </v-col>
                  </div>
                  <div v-if="car.features[0].gps">
                    <v-col class="d-flex align-center mx-auto">
                      <div v-if="car.features[0].gps == 'true'">
                        <v-img
                          src="../assets/images/gps.png"
                          style="margin-left: 1% !important"
                          width="30px"
                        ></v-img>
                      </div>
                    </v-col>
                  </div>
                  <div v-if="car.features[0].bluetooth">
                    <v-col class="d-flex align-center mx-auto">
                      <div v-if="car.features[0].bluetooth == 'true'">
                        <v-img
                          src="../assets/images/bluetooth.png"
                          style="margin-left: 1% !important"
                          width="30px"
                        ></v-img>
                      </div>
                    </v-col>
                  </div>
                  <div v-if="car.features[0].usb">
                    <v-col class="d-flex align-center mx-auto">
                      <div v-if="car.features[0].usb == 'true'">
                        <v-img
                          src="../assets/images/usb.png"
                          style="margin-left: 1% !important"
                          width="30px"
                        ></v-img>
                      </div>
                    </v-col>
                  </div>
                  <div v-if="car.features[0].aux">
                    <v-col class="d-flex align-center mx-auto">
                      <div v-if="car.features[0].aux == 'true'">
                        <v-img
                          src="../assets/images/audio-cable.png"
                          style="margin-left: 1% !important"
                          width="30px"
                        ></v-img>
                      </div>
                    </v-col>
                  </div>
                  <div v-if="car.features[0].smoking">
                    <v-col class="d-flex align-center mx-auto">
                      <div v-if="car.features[0].smoking == 'false'">
                        <v-img
                          src="../assets/images/smoking.png"
                          style="margin-left: 1% !important"
                          width="30px"
                        ></v-img>
                      </div>
                    </v-col>
                  </div>
                </v-row>
                <!-- </div> -->
              </v-card>
            </v-container>
          </v-tab-item>
        </v-tabs-items>
        <v-row align="center">
          <v-col :cols="4" align="center">
            <v-btn class="btnWa" @click="$router.push({ path: '/' })"
              ><v-icon
                style="
                          background-image: linear-gradient(to right, #ad5389, #3c1053) !important;
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        "
              >
                mdi-home-circle-outline mdi-18px </v-icon
              ><v-card-text class="text-center header2"
                >ALL CARS</v-card-text
              ></v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import DataService from "../services/dataService.js";
const dataService = new DataService();
// import store from "./../app.store";

export default {
  data() {
    return {
      // name : this.$store.state.userProfile.name
      tab: "LOCAL",
      tab1: "OUR CARS",
      cars: [],
      currentCarIndex: 0,
      activeSlide: 0,
      disabled: false,
    };
  },

  mounted: function () {
    this.getAvailableCars();
  },

  computed: {
    generateCombinedModels() {
      let CombinedModels = this.cars.map((car) => car.make + " " + car.model);
      return CombinedModels;
    },
    groupedCars() {
      const categoryOrder = [
        "LOCAL",
        "MPV & VAN",
        "SUV",
        "LUXURY SEDAN",
        "PREMIUM",
        "4WD DRIVE VEHICLES",
        "2 DOOR SPORTS COUPE",
        "SUPERCAR",
      ];
      const grouped = this.cars.reduce((grouped, car) => {
        let c = car.category.toUpperCase();
        if (!grouped[c]) {
          grouped[c] = [];
        }
        const existingCarIndex = grouped[c].findIndex(
          (existingCar) =>
            existingCar.make === car.make && existingCar.model === car.model
        );
        if (existingCarIndex >= 0) {
          // Car already exists in group, add color to existing car's color array
          const existingCar = grouped[c][existingCarIndex];
          if (!existingCar.colors.includes(car.color)) {
            existingCar.colors.push(car.color);
            console.log(existingCar.colors)
          }
        } else {
          // Car does not exist in group, add it
          grouped[c].push({
            make: car.make,
            model: car.model,
            colors: [car.color],
            imageUrl: car.imageUrl,
            pricePerDay: ["RM " + car.pricePerDay.toString() + " CAR RENTAL DAILY"],
            pricePer3Days: ["RM " + car.pricePer3days.toString() + " CAR RENTAL 3 DAYS"],
            pricePerWeek: ["RM " + car.pricePerWeek.toString() + " / CAR RENTAL WEEKLY"],
            // pricePerMonth: ["RM " + car.pricePerMonth.toString() + " / CAR RENTAL MONTHLY"],
            features: car.features,
          });
        }
        console.log(grouped)
        return grouped;
      }, {});
      return Object.keys(grouped)
        .sort((a, b) => categoryOrder.indexOf(a) - categoryOrder.indexOf(b))
        .reduce((sorted, category) => {
          sorted[category] = grouped[category].sort((carA, carB) => {
            const priceA = parseInt(carA.pricePerDay[0].split(" ")[1]);
            const priceB = parseInt(carB.pricePerDay[0].split(" ")[1]);
            return priceA - priceB;
          });
          return sorted;
        }, {});
    },
  },

  methods: {
    async getAvailableCars() {
      this.$setLoader();
      await dataService.getAvailableCars().then((res) => {
        for (let car of res.data.availableCars) {
          if (car.rentStatus != "Inactive") {
            car.imageUrl[4] = car.imageUrl[1];
            this.cars.push(car);
          }
        }
      });

      this.$disableLoader();
    },
  },
};
</script>

<style scoped lang="scss">
#particles-js {
  background-color: #f9ece0;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
}
.container {
  /* margin-top: 5%; */
  height: fit-content !important;
}

.nav-items {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    padding: 0 10px;
  }
}
i {
  display: none;
}

.v-application .elevation-8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.2), 0px 3px 14px 2px rgba(0, 0, 0, 0.2) !important;
}

.v-application .elevation-12 {
  box-shadow: 0px 5px 5px -3px #00000049, 0px 8px 10px 1px #0000004d,
    0px 3px 14px 2px #00000054 !important;
}
</style>
